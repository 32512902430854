#name, #email, #number {
    display: block;
    width: 95%;
    border-radius: 5px;
    padding: 10px 2.5%;
    margin: 10px 0 25px;
}

input,select {
    font-size: 1.05em;
    border: none;
    background-color: rgba(245, 245, 245, 0.87);
}