.btn {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 10px 0;
    font-size: larger;
    color: #f3f3f3;
    background-color: rgba(3, 153, 71, 0.836);
    cursor: pointer;
    margin-bottom: 25px;
}

.btn:hover {
    background-color: rgba(3, 153, 71, 0.623);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    transition: 0.2s ease-out;
}