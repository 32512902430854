.lbl {
    font-size: 1.5em;
}

.check-grp label {
    display: block;
    margin-bottom: 10px;
    padding: 2px 0;
}

.check-grp {
    margin-bottom: 25px;
}

input[type="checkbox"] {
    height:25px; 
    width:25px; 
    vertical-align: middle;
    margin-right: 10px;
}